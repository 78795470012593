import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { ButtonWrapper } from "./button";

export const ButtonGroupWrapper = styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  vertical-align: middle;
  width: 100%;

  > ${ButtonWrapper} {
    margin-bottom: ${props => props.theme.spacing.regular};
  }

  > ${ButtonWrapper}:last-child {
    margin-bottom: 0;
  }

  ${props =>
    props.block &&
    css`
      display: flex;

      ${ButtonWrapper} {
        width: 100%;
      }
    `};
  
  flex-direction: ${props => (props.vertical ? "column" : "row")};
  
  > ${ButtonWrapper} {
    margin-bottom: ${props => !props.vertical && "0"};
    margin-right: ${props => !props.vertical && props.theme.spacing.large};
  }

  > ${ButtonWrapper}:last-child {
    margin-right: 0;
  }
  
  ${props =>
    props.block &&
    css`
      display: flex;
      flex-direction: row;
    `};
`;

const ButtonGroup = ({ children, vertical, block, ...props }) => (
  <ButtonGroupWrapper block={block} vertical={vertical} {...props}>
    {children}
  </ButtonGroupWrapper>
);

ButtonGroup.propTypes = {
  children: PropTypes.any.isRequired,
  block: PropTypes.bool,
  vertical: PropTypes.bool
};

ButtonGroup.defaultProps = {
  block: false,
  vertical: false
};

export default ButtonGroup;
