import "normalize.css";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import styled, { ThemeProvider } from "styled-components";
import theme, { GlobalStyle } from "../../theme/default";
import { Row, Col, Container } from "../container";
import { Link } from "gatsby";

const StyledContainer = styled(Container)`
  margin-top: ${props => props.theme.spacing.regular};
  margin-bottom: ${props => props.theme.spacing.small};
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.color.quaternary};
  font-weight: 700;

  &:active,
  &:hover {
    text-decoration: none !important;
  }
`;

const Layout = ({
  children,
  rightButtonLink,
  rightButtonText,
  leftButtonLink,
  leftButtonText
}) => (
  <ThemeProvider theme={theme}>
    <React.Fragment>
      <StyledContainer>
        <Row wrap={false}>
          {leftButtonLink && (
            <Col>
              <StyledLink to={leftButtonLink}>{leftButtonText}</StyledLink>
            </Col>
          )}
          {rightButtonLink && (
            <Col textalign="right">
              <StyledLink to={rightButtonLink}>{rightButtonText}</StyledLink>
            </Col>
          )}
        </Row>
      </StyledContainer>
      {children}
      <GlobalStyle />
    </React.Fragment>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.any.isRequired
};

export default Layout;
