import { transitions } from "polished";
import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby"

const ButtonTypes = {
  primary: "primary",
  secondary: "secondary"
};

export const ButtonWrapper = styled.button`
  border-radius: ${props => props.theme.radius};
  height: ${props => props.theme.button.height};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: 0;
  border: 0;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  position: relative;
  white-space: nowrap;
  width: 100%;

  ${transitions(
    ["background-color", "color", "border-color"],
    "100ms ease-in-out"
  )};  

  ${props =>
    props.as === Link &&
    css`
      &:hover {
        text-decoration: none;
      }
    `};
  
   
  ${props => props.theme.media.tabletL} {
      width: auto;

      ${props =>
        props.width &&
        css`
          min-width: ${props.width}px;
        `};

      padding-left: ${props => props.theme.spacing.large};
      padding-right: ${props => props.theme.spacing.large};
    }
  
  // Primary outline
  ${props =>
    props.type === ButtonTypes.primary &&
    props.outline &&
    css`
      color: ${props.theme.button.type.primary.color};
      border-color: ${props.theme.button.type.primary.color};

      &:hover {
        background-color: ${props.theme.button.type.primary.hover};
        border-color: ${props.theme.button.type.primary.hover};
        color: ${props.theme.color.textDefault};
      }
    `};
  
  // Primary link
  ${props =>
    props.type === ButtonTypes.primary &&
    props.link &&
    css`
      color: ${props.theme.button.type.primary.color};
      background-color: transparent;

      &:hover {
        text-decoration: underline;
      }
    `};
  
  // Primary
  ${props =>
    props.type === ButtonTypes.primary &&
    !props.outline &&
    !props.link &&
    css`
      color: ${props.theme.color.textDefault};
      background-color: ${props.theme.button.type.primary.color};

      &:hover {
        background-color: ${props.theme.button.type.primary.hover};
      }
    `}
  
  // Secondary outline
  ${props =>
    props.type === ButtonTypes.secondary &&
    props.outline &&
    css`
      color: ${props.theme.button.type.secondary.color};
      border-color: ${props.theme.button.type.secondary.color};

      &:hover {
        background-color: ${props.theme.button.type.secondary.hover};
        border-color: ${props.theme.button.type.secondary.hover};
        color: ${props.theme.color.textLight};
      }
    `};
  
  // Secondary link
  ${props =>
    props.type === ButtonTypes.secondary &&
    props.link &&
    css`
      color: ${props.theme.button.type.secondary.color};
      background-color: transparent;

      &:hover {
        text-decoration: underline;
      }
    `};
  
  // Secondary
  ${props =>
    props.type === ButtonTypes.secondary &&
    !props.outline &&
    !props.link &&
    css`
      color: ${props.theme.color.textLight};
      background-color: ${props.theme.button.type.secondary.color};

      &:hover {
        background-color: ${props.theme.button.type.secondary.hover};
      }
    `}
  
  ${props =>
    props.outline &&
    css`
      border-width: 1px;
      border-style: solid;
      background-color: transparent;
    `};
`;

const Button = ({ children, type, width, outline, link, as, to, ...props }) => {
  let elem = as;
  if (to && elem === "button") {
    elem = Link;
  }

  if (typeof children === "string" && children.trim() === "") {
    return null;
  }

  return (
    <ButtonWrapper
      as={elem}
      to={to}
      width={width}
      type={type}
      link={link ? 1 : 0}
      outline={outline ? 1 : 0}
      {...props}
    >
      {children}
    </ButtonWrapper>
  );
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  as: PropTypes.string,
  width: PropTypes.number,
  to: PropTypes.oneOfType([
    PropTypes.shape({
      link_type: PropTypes.string,
      url: PropTypes.string,
      slug: PropTypes.string,
      target: PropTypes.string
    }),
    PropTypes.string
  ]),
  type: PropTypes.oneOf(Object.keys(ButtonTypes)),
  outline: PropTypes.bool,
  link: PropTypes.bool
};

Button.defaultProps = {
  width: 0,
  as: "button",
  type: ButtonTypes.primary,
  outline: null,
  to: null,
  link: false
};

Button.types = ButtonTypes;

export default Button;
