import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";

export const ColWrapper = styled.div`
  text-align: ${props => props.textalign};
`;

export const ColInsideWrapper = styled.div`
  height: 100%;
`;

const Col = ({ textalign, children, ...props }) => (
  <ColWrapper as={Grid.Unit} textalign={textalign} {...props}>
    <ColInsideWrapper>{children}</ColInsideWrapper>
  </ColWrapper>
);

Col.propTypes = {
  children: PropTypes.any.isRequired,
  textalign: PropTypes.string
};

Col.defaultProps = {
  textalign: null
};

export default Col;
