import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";

const HeadingSize = {
  veryLarge: "veryLarge",
  large: "large",
  regular: "regular"
};

export const HeadingWrapper = styled.span`
  ${props =>
    typeof props.theme.text[props.size].size === "string" &&
    css`
      font-size: ${props.theme.text[props.size].size};
    `};

  ${props =>
    typeof props.theme.text[props.size].size === "object" &&
    css`
      font-size: ${props.theme.text[props.size].size.mobile};

      ${props.theme.media.tabletL} {
        font-size: ${props.theme.text[props.size].size.tabletL};
      }
    `};

  font-weight: ${props => props.theme.text[props.size].weight};
  font-family: ${props => props.theme.text[props.size].family};
  color: ${props => props.theme.color.textDefault};

  ${props =>
    props.invert &&
    css`
      color: ${props.theme.color.textLight};
    `};

  margin-top: ${props => props.theme.spacing.regular};
  margin-bottom: ${props => props.theme.spacing.regular};

  ${props =>
    props.subtitle &&
    css`
      margin-top: 0;
      margin-bottom: 0;
    `};
`;

const Heading = ({ elem, size, children, invert, subtitle, ...props }) => {
  if (!children) {
    return null;
  }

  return (
    <HeadingWrapper
      as={elem}
      size={size}
      invert={invert}
      subtitle={subtitle}
      {...props}
    >
      {children}
    </HeadingWrapper>
  );
};

Heading.propTypes = {
  children: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(HeadingSize)),
  elem: PropTypes.string.isRequired,
  invert: PropTypes.bool,
  subtitle: PropTypes.bool
};

Heading.defaultProps = {
  children: null,
  size: HeadingSize.regular,
  invert: false,
  subtitle: false
};

Heading.sizes = HeadingSize;

export default Heading;
