import { createGlobalStyle } from "styled-components";
import GilroyAltBoldEot from "./fonts/GilroyAlt-Bold.eot";

import GilroyAltBoldWoff from "./fonts/GilroyAlt-Bold.woff";
import GilroyAltBoldWoff2 from "./fonts/GilroyAlt-Bold.woff2";
import GilroyAltMediumEot from "./fonts/GilroyAlt-Medium.eot";

import GilroyAltMediumWoff from "./fonts/GilroyAlt-Medium.woff";
import GilroyAltMediumWoff2 from "./fonts/GilroyAlt-Medium.woff2";
import GilroyAltSemiBoldEot from "./fonts/GilroyAlt-SemiBold.eot";
import GilroyAltSemiBoldWoff from "./fonts/GilroyAlt-SemiBold.woff";
import GilroyAltSemiBoldWoff2 from "./fonts/GilroyAlt-SemiBold.woff2";

const breakpoints = {
  mobile: 0,
  tabletP: 600,
  tabletL: 900,
  desktop: 1200,
  bigDesktop: 1800
};

const theme = {
  breakpoints,
  radius: "8px",
  spacing: {
    mega: "128px",
    superMassive: "80px",
    massive: "64px",
    veryHuge: "56px",
    huge: "40px",
    veryLarge: "32px",
    large: "24px",
    regular: "16px",
    small: "8px",
    verySmall: "4px",
    zero: "0px"
  },
  color: {
    primary: "#03A58F",
    secondary: "#004C6A",
    tertiary: "#FFCD00",
    quaternary: "#0061F2",
    textLight: "#FFFFFF",
    textDefault: "#233645",
    textPrimary: "#004C6A",
    textSecondary: "#4F5E6A",
    focusHighlight: "#E5C1C5",
    backgroundVeryLight: "#FBF6F7",
    backgroundLight: "#F7ECEE",
    backgroundDark: "#E6F5F4",
    backgroundVeryDark: "#E6F5F4",
    backgroundWhite: "#FFFFFF",
  },
  text: {
    veryLarge: {
      size: {
        mobile: "26px",
        tabletL: "2.6rem"
      },
      family: "GilroyAlt, Arial, Sans-Serif",
      weight: "600"
    },
    large: {
      size: "3.6rem",
      family: "GilroyAlt, Arial, Sans-Serif",
      weight: "600"
    },
    regular: {
      size: "1.8rem",
      family: "GilroyAlt, Arial, Sans-Serif",
      weight: "700"
    },
    body: {
      size: "1.8rem",
      family: "GilroyAlt, Arial, Sans-Serif",
      weight: "500"
    },
    small: {
      size: "1.2rem",
      family: "GilroyAlt",
      weight: "600"
    }
  },
  sizes: {
    control: "60px",
    icon: "3.2rem",
    image: "56px",
    illustration: {
      desktop: "224px",
      mobile: "184px"
    },
    border: "1px"
  },
  sticker: {
    color: "#EF6955",
    fontSize: "1.6rem"
  },
  button: {
    type: {
      primary: {
        color: "#FFCD00",
        hover: "#FFDC4D"
      },
      secondary: {
        color: "#004C6A",
        hover: "#337088"
      }
    },
    width: "200px",
    height: "60px"
  },
  media: Object.keys(breakpoints).reduce((acc, label) => {
    acc[label] = `@media (min-width: ${breakpoints[label]}px)`;

    return acc;
  }, {}),
};

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'GilroyAlt';
    src: url(${GilroyAltMediumEot});
    src: url('${GilroyAltMediumEot}?#iefix') format('embedded-opentype'),
         url(${GilroyAltMediumWoff2}) format('woff2'),
         url(${GilroyAltMediumWoff}) format('woff');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'GilroyAlt';
    src: url(${GilroyAltSemiBoldEot});
    src: url('${GilroyAltSemiBoldEot}?#iefix') format('embedded-opentype'),
         url(${GilroyAltSemiBoldWoff2}) format('woff2'),
         url(${GilroyAltSemiBoldWoff}) format('woff');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'GilroyAlt';
    src: url(${GilroyAltBoldEot});
    src: url('${GilroyAltBoldEot}?#iefix') format('embedded-opentype'),
         url(${GilroyAltBoldWoff2}) format('woff2'),
         url(${GilroyAltBoldWoff}) format('woff');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }
  
  html {
    font-size: 55.5%;
    box-sizing: border-box;
    
    ${props => props.theme.media.tabletL} {
      font-size: 59%;
    }
    
    ${props => props.theme.media.desktop} {
      font-size: 62.5%;
    }
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    font-size: ${props => props.theme.text.body.size}; 
    font-weight: ${props => props.theme.text.body.weight};
    font-family: ${props => props.theme.text.body.family};
    color: ${props => props.theme.color.textSecondary};
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  input, textarea, select, button {
    font-size: ${props => props.theme.text.body.size}; 
    font-weight: ${props => props.theme.text.body.weight};
    font-family: ${props => props.theme.text.body.family};
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  ul {
    list-style-position: inside;
  }
  
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.2;
  }
  
  a {
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default theme;
