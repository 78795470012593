import PropTypes from "prop-types";
import React from "react";
import styled, { css } from "styled-components";
import Grid from "styled-components-grid";
import { ColInsideWrapper, ColWrapper } from "./col";

export const RowSpacing = {
  superMassive: "superMassive",
  massive: "massive",
  huge: "huge",
  veryLarge: "veryLarge",
  large: "large",
  regular: "regular",
  small: "small",
  verySmall: "verySmall",
  zero: "zero"
};

export const RowWrapper = styled.div`
  height: 100%;

  ${props =>
    props.spacing &&
    css`
      margin-left: -${props.theme.spacing[props.spacing]};
      margin-right: -${props.theme.spacing[props.spacing]};

      > ${ColWrapper} > ${ColInsideWrapper} {
        padding-left: ${props.theme.spacing[props.spacing]};
        padding-right: ${props.theme.spacing[props.spacing]};
      }
    `};

  ${props =>
    props.fullheight &&
    css`
      height: 100vh;
    `};
`;

const Row = ({ spacing, fullheight, ...props }) => (
  <RowWrapper
    as={Grid}
    {...props}
    spacing={spacing}
    fullheight={fullheight ? 1 : 0}
  />
);

Row.propTypes = {
  fullheight: PropTypes.bool,
  spacing: PropTypes.string
};

Row.defaultProps = {
  fullheight: false,
  spacing: null
};

export default Row;
