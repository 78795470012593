import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

export const ContainerWrapper = styled.div`
  max-width: 100%;
  width: calc(100% - ${props => props.theme.spacing.veryLarge});
  margin: 0 auto;
  position: relative;
  height: 100%;

  ${props =>
    props.fluid &&
    css`
      max-width: none;
      width: auto;
    `};

  ${props => props.theme.media.tabletL} {
    max-width: 840px;
    width: 840px;
  }

  ${props => props.theme.media.desktop} {
    max-width: 1140px;
    width: 1140px;
  }
`;

const Container = ({ children, fluid, ...props }) => (
  <ContainerWrapper fluid={fluid} {...props}>{children}</ContainerWrapper>
);

Container.propTypes = {
  children: PropTypes.any.isRequired,
  fluid: PropTypes.bool
};

Container.defaultProps = {
  fluid: false
};

export default Container;
