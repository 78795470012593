import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import ButtonGroup from "./buttonGroup";

export const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: ${props => props.theme.spacing.huge};
`;

const ButtonBar = ({ children, block, ...props }) => (
  <StyledButtonGroup block={block} {...props}>
    {children}
  </StyledButtonGroup>
);

ButtonBar.propTypes = {
  children: PropTypes.any.isRequired,
  block: PropTypes.bool
};

ButtonBar.defaultProps = {
  block: false
};

export default ButtonBar;
